/* Dashboard.css */
.kanban-dashboard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0px;
    /*margin-top: 20px;*/
    margin-bottom: 20px;
}

.kanban-card {
    padding: 20px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 6px;
}
